<template>
    <div class="w-full">
        <swiper class="swiper w-full" :loop="true">
            <swiper-slide v-for="(item, index) in items" :key="index" class="slide w-full">
                <div class="w-screen h-screen">
                    <div class="hero-img w-screen bg-black" :style="{backgroundImage: `url(${item.image})`}"></div>
                    <!-- <img :src="item.image" class="absolute hero-img" :class="isFullWidth ? 'w-full' : 'h-full'" /> -->
                    <div class="w-full h-full flex items-center justify-center" v-if="item.label">
                        <div class="text relative container">
                            <h2 class="text-6xl font-semibold" :class="{ 'text-white': item.mode == 'dark' }"
                                v-html="item.label"></h2>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        Swiper, SwiperSlide
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        isFullWidth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
}
</script>